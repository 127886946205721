export default [
  {
    path: '/reports/service/service-tracing-interviews',
    name: 'ServiceTracingInterviews',
    component: () => import('@/views/Reports/Service/ServiceTracingInterviews.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Randevu Görüşme Notları',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICES_INTERVIEWS',
      action: 'read',
    },
  },
  {
    path: '/reports/service/bcs-sms',
    name: 'ReportsBcsSms',
    component: () => import('@/views/Reports/Service/BcsSms.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'BCS SMS',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICE_BCS_SMS',
      action: 'read',
    },
  },
  {
    path: '/reports/service/birthday-sms',
    name: 'ReportsBirthdaySms',
    component: () => import('@/views/Reports/Service/BirthdaySms.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Doğum Günü SMS',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICE_BIRTHDAY_SMS',
      action: 'read',
    },
  },
  {
    path: '/reports/service/service-tracing',
    name: 'ReportsServiceTracing',
    component: () => import('@/views/Reports/Service/ServiceTracing.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Randevu Adetleri',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICE_TRACING',
      action: 'read',
    },
  },
  {
    path: '/reports/service/accessories',
    name: 'ReportsServiceAccessories',
    component: () => import('@/views/Reports/Service/Accessories.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Aksesuar Randevuları',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICE_ACCESSORIES',
      action: 'read',
    },
  },
  {
    path: '/reports/service/work-orders',
    name: 'ReportsServiceWorkOrder',
    component: () => import('@/views/Reports/Service/ServiceWorkOrders.vue'),
    meta: {
      pageTitle: 'Servis',
      breadcrumb: [
        {
          text: 'Servis İş Emirleri Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_SERVICES_WORK_ORDERS',
      action: 'read',
    },
  },
]
