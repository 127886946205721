import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    counters: [],
    dataItem: {
      id: null,
      tracking_no: null,
      entry_date: moment().format('YYYY-MM-DD'),
      release_date: null,
      payment_type: 0,
      expertise_price: null,
      vip: null,
      id_com_damage_tracking_type: null,
      id_com_damage_tracking_insurance: null,
      id_com_cars: null,
      id_com_customer: null,
      id_com_location: null,
      id_com_user: null,
      submitStatus: false,
    },
    filterData: {
      keyword: '',
      sdate: null,
      edate: null,
      id_com_damage_tracking_status: null,
      id_com_location: null,
      detailSearch: null,
      id_com_user: null,
    },
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    saveDataStatus(state) {
      return state.saveData
    },
    getCounters(state) {
      return state.counters
    },
    filterData(state) {
      return state.filterData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_COUNTERS(state, data) {
      state.counters = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        tracking_no: null,
        entry_date: moment().format('YYYY-MM-DD'),
        release_date: null,
        payment_type: 0,
        expertise_price: null,
        vip: null,
        id_com_damage_tracking_type: null,
        id_com_damage_tracking_insurance: null,
        id_com_cars: null,
        id_com_customer: null,
        id_com_location: null,
        id_com_user: null,
        submitStatus: false,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/damage_tracking', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_COUNTERS', response.data.counters)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_damage_tracking.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/damage_tracking', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/damage_tracking/saveData', data)
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/damage_tracking/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
